<template>
  <div class="root">
    <el-card class="top-card">
      <div class="left">
        <h3>{{ isStudent ? "学习数据" : "授课数据" }}</h3>
        <div class="data">
          <div class="finished">
            <p>{{ stuCour.finishedNum }}</p>
            <h4>已完成课程</h4>
          </div>
          <div class="unfinished">
            <p>
              <span class="title">全部课程：</span
              ><span class="num">{{ stuCour.total }}节</span>
            </p>
            <p>
              <span class="title">{{
                isStudent ? "已学习时长：" : "已授课时长："
              }}</span
              ><span class="num">{{ stuCour.duration }}小时</span>
            </p>
            <p>
              <span class="title">{{
                isStudent ? "待学习：" : "待授课："
              }}</span
              ><span class="num">{{ stuCour.willFinishedNum }}节</span>
            </p>
          </div>
        </div>
      </div>
      <div v-if="isStudent" class="right">
        <h3>个人信用</h3>
        <div class="data">
          <div class="finished">
            <p>{{ isOk ? "优秀" : "待提高" }}</p>
            <h4>预约信用</h4>
          </div>
          <div class="unfinished">
            <p>
              <span class="title">评价A：</span
              ><span class="num">{{ stuEval.excellent }}次</span>
            </p>
            <p>
              <span class="title">评价B：</span
              ><span class="num">{{ stuEval.ordinary }}次</span>
            </p>
            <p>
              <span class="title">评价C：</span
              ><span class="num">{{ stuEval.bad }}次</span>
            </p>
            <p>
              <span class="title">缺勤：</span
              ><span class="num">{{ stuEval.absent }}次</span>
            </p>
          </div>
        </div>
      </div>
    </el-card>
    <el-card v-if="appointmentCourseList.length > 0" class="appointment-card">
      <h3>{{ isStudent ? "我的预约" : "我的课程" }}</h3>
      <p class="cf">请注意课程时间，提前到达现场</p>
      <div class="course">
        <div
          v-for="(item, i) in appointmentCourseList"
          :key="i"
          class="course-item"
        >
          <el-image
            style="width: 140px; height: 90px"
            :src="item.coursePicture"
            fit="cover"
          ></el-image>
          <div class="content">
            <p class="courseSite">{{ item.courseSite }}</p>
            <p class="courseName">{{ item.courseName }}</p>
            <p class="course-time">
              {{ item.courseTime || item.courseLearningTime }}
            </p>
            <button class="btn" @click="handleCourseLook">
              查看
            </button>
          </div>
        </div>
      </div>
    </el-card>
    <el-card
      v-if="teacherAppointmentCourseList.length > 0 && !isStudent"
      class="appointment-card"
    >
      <h3>课程通知<span @click="$router.push('/user/myCourse/arrange')" class="lookMore">更多>></span></h3>
      <div class="main">
        <h4>
          <span>授课时间</span>
          <span>课程状态</span>
          <span>课程名称</span>
          <span>课程人数</span>
          <span>地点场景</span>
          <span>学生名单</span>
        </h4>
        <div
          v-for="(item, index) in teacherAppointmentCourseList"
          :key="index"
          class="table"
        >
          <div :class="item.showStudentList?'row show':'row'">
            <p>{{ item.courseTime || item.courseLearningTime }}</p>
            <p>
              {{
                item.teachingStatus == 2
                  ? "即将上课"
                  : item.teachingStatus == 3
                  ? "已完结"
                  : "正在进行"
              }}
            </p>
            <p>{{ item.courseName }}</p>
            <p>{{ item.studentList.length }}</p>
            <p>{{ item.courseSite }}</p>
            <p @click="lookStudents(item)">
              {{ item.studentList.length > 0 ? "查看" : "暂无学生" }}
            </p>
          </div>
          <div :class="item.showStudentList?'studentList showList':'studentList'">
            <el-table size="mini" :data="item.studentList" align="center" style="width: 100%">
              <el-table-column prop="studentName" label="姓名" width="150">
              </el-table-column>
              <el-table-column label="性别" width="150">
                <template slot-scope="scope">
                  <span>{{ scope.row.sex==0?'女':'男' }}</span>
                </template>
              </el-table-column>
              <el-table-column prop="gradeName" label="年级" width="150">
              </el-table-column>
              <el-table-column prop="schoolName" label="学校"> </el-table-column>
            </el-table>
          </div>
        </div>
      </div>
    </el-card>
    <el-card v-if="isStudent" class="echarts-card">
      <div slot="header" class="clearfix">
        <span class="echarts-title">我的统计</span>
        <p class="echarts-nav">
          <span
            @click="showCharts = !showCharts"
            :class="showCharts ? 'active' : ''"
            >雷达</span
          >
          <span
            @click="showCharts = !showCharts"
            :class="!showCharts ? 'active' : ''"
            >图表</span
          >
        </p>
      </div>
      <div class="echarts">
        <Echarts
          v-if="flag"
          :labelCount="labelCount"
          :showCharts="showCharts"
        />
      </div>
    </el-card>
    <el-card v-if="messageList.length > 0" class="msg-card">
      <div slot="header" class="clearfix cf">
        <span class="fl">系统消息</span>
        <span
          @click="$router.push('/user/index/myMessage')"
          class="grid-content more fr"
          >更多>></span
        >
      </div>
      <el-row v-for="(item, i) in messageList" :key="i" :gutter="20">
        <el-col :span="4"
          ><div class="grid-content">{{ item.messageTypeName }}</div></el-col
        >
        <el-col :span="13"
          ><div class="grid-content">{{ item.msg }}</div></el-col
        >
        <el-col :span="7"
          ><div class="grid-content">{{ item.createTime }}</div></el-col
        >
      </el-row>
    </el-card>
  </div>
</template>

<script>
import {
  getStuEval_api,
  getStuCour_api,
  isExcellent_api,
  appointmentCourse_api,
  portalManager_api,
  getLabelCount_api,
  countFinishedTeachRecord_api,
  getTeacherTeachRecordList_api,
} from "@/api/user.js";
import { sessionGet } from "@/utils/local.js";
import Echarts from "./components/echarts.vue";
export default {
  components: {
    Echarts,
  },
  data() {
    return {
      appointmentCourseList: [], // 已预约课程列表(长度2)
      teacherAppointmentCourseList: [], // 已预约课程列表(长度5，只在教师登录)
      stuCour: {}, // 学生学习数据
      isOk: true, // 是否优秀
      stuEval: {}, // 学生的评价信息
      messageList: [], // 消息列表
      labelCount: {}, // 雷达图数据
      // 控制Chart组件何时创建的标杆，Chart先创建（同步），axios后回来的问题（异步）
      flag: false,
      showCharts: true, // 显示什么图形，true表示雷达图，false表示柱状图
    };
  },
  methods: {
    // 根据用户角色请求不同数据
    isStudentGetData() {
      if (this.isStudent) {
        this.getStuEval();
        this.getStuCour();
        this.isExcellent();
        this.appointmentCourse();
        this.getLabelCount();
        this.portalManager();
      } else {
        this.countFinishedTeachRecord();
        this.getTeacherTeachRecordList();
        this.portalManager();
      }
    },
    // 获取教师授课数据
    async countFinishedTeachRecord() {
      let res = await countFinishedTeachRecord_api({
        userId: sessionGet("userId"),
      });
      this.stuCour = res;
    },
    // 获取教师的授课列表
    async getTeacherTeachRecordList() {
      let res = await getTeacherTeachRecordList_api({
        userId: sessionGet("userId"),
        pageSize: 5,
        currentPage: 1,
        teachingStatus: 2, // 2表示即将开课，3表示已完结，1表示正在进行
      });
      this.teacherAppointmentCourseList = res.resultList.map((item)=>{
        item.showStudentList = false // 添加是否显示学生列表
        return item
      });
      if (res.resultList.length > 2)
        this.appointmentCourseList = res.resultList.slice(0, 2);
      else this.appointmentCourseList = res.resultList;
    },
    // 查看学生列表
    lookStudents(item) {
      if (item.studentList.length <= 0) {
        this.$message.error("该课程暂无学生");
      } else if(item.showStudentList) {
        this.teacherAppointmentCourseList.forEach(val=>{
          val.showStudentList = false // 关闭所有
        })
      }else{
        this.teacherAppointmentCourseList.forEach(val=>{
          val.showStudentList = false // 关闭所有
        })
        item.showStudentList = true
      }
    },
    // 点击课程的查看
    handleCourseLook(){
      if(this.isStudent) this.$router.push("/user/order/myOrder")
      else this.$router.push("/user/myCourse/arrange")
    },
    // 获取学生评价信息
    async getStuEval() {
      let res = await getStuEval_api({ userId: sessionGet("userId") });
      this.stuEval = res;
    },
    // 获取学生学习数据
    async getStuCour() {
      let res = await getStuCour_api({ userId: sessionGet("userId") });
      this.stuCour = res;
    },
    // 学生是否优秀
    async isExcellent() {
      let res = await isExcellent_api({ userId: sessionGet("userId") });
      this.isOk = res || true;
    },
    // 获取预约的课程列表
    async appointmentCourse() {
      let { code, data } = await appointmentCourse_api({
        studentId: sessionGet("userId"),
        size: 2,
        current: 1,
        reserveStatus:1, // 课程状态，1表示已成功
      });
      if (code === 200) this.appointmentCourseList = data.records;
    },
    // 获取雷达图数据
    async getLabelCount() {
      this.flag = false;
      let res = await getLabelCount_api({ userId: sessionGet("userId") });
      this.labelCount = res;
      this.flag = true;
    },
    // 获取通知消息
    async portalManager() {
      let { code, data } = await portalManager_api({
        userId: sessionGet("userId"),
        page: 1,
        size: 5,
      });
      if (code === 200) {
        this.messageList = data.list;
      }
    },
  },
  computed: {
    isStudent() {
      return (
        this.$store.state.userInfo.roleName === "student" ||
        this.$store.state.userInfo.roleName === "学生"
      );
    },
  },
  created() {
    this.isStudentGetData();
  },
};
</script>

<style lang="less" scoped>
@title-color: #0079fe;
@btn-color: #f66866;
.root {
  .top-card {
    margin-bottom: 30px;
    /deep/.el-card__body {
      display: flex;
      .left,
      .right {
        flex: 1;
        h3 {
          font-weight: 700;
        }
        .data {
          display: flex;
          .finished {
            width: 40%;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            color: @btn-color;
            p {
              font-weight: 700;
              font-size: 30px;
              margin-bottom: 20px;
            }
            h4 {
              font-size: 14px;
            }
          }
          .unfinished {
            width: 60%;
            p {
              display: flex;
              padding: 15px;
              font-size: 14px;
              .title {
                width: 60%;
                color: #999;
              }
              .num {
                color: @btn-color;
              }
            }
          }
        }
      }
      .right .data .unfinished p {
        padding: 10px 15px;
      }
    }
  }
  .appointment-card {
    margin-bottom: 30px;
    h3 {
      font-weight: 700;
      .lookMore{
        font-size: 14px;
        float: right;
        &:hover{
          color:@title-color;
          user-select: none;
          cursor: pointer;
        }
      }
    }
    .cf {
      color: #888;
      font-size: 14px;
      margin: 10px 0;
    }
    .course {
      display: flex;
      .course-item {
        width: 50%;
        display: flex;
        justify-content: space-between;
        .el-image {
          margin-right: 10px;
        }
        .content {
          width: calc(100% - 150px);
          color: #666;
          font-size: 14px;
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          p {
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
          }
          .btn {
            color: @title-color;
            background-color: #fff;
            border: 1px solid @title-color;
            width: 100px;
            border-radius: 20px;
          }
        }
      }
    }
    // 教师-课程通知
    .main {
      font-size: 14px;
      text-align: center;
      border-bottom: 1px solid #f4f4f4;
      h4 {
        margin-top: 20px;
        background-color: #eee;
        line-height: 40px;
        display: flex;
        span {
          &:nth-child(2n-1) {
            flex: 3;
          }
          &:nth-child(2n) {
            flex: 1;
          }
          &:nth-child(1) {
            flex: 2;
          }
        }
      }
      .table {
        .row {
          display: flex;
          border-top: 1px solid #f4f4f4;
          border-bottom: 1px solid #f4f4f4;
          background-color: #f8f8f8;
          color:#777;
          &.show{
            background-color: #f2f2f2;
          }
          p {
            display: flex;
            justify-content: center;
            align-items: center;
            line-height: 1.7em;
            min-height: 30px;
            &:nth-child(2n-1) {
              flex: 3;
            }
            &:nth-child(2n) {
              flex: 1;
            }
            &:nth-child(1) {
              flex: 2;
            }
            &:last-child {
              color:@title-color;
              cursor: pointer;
              user-select: none;
            }
          }
        }
        .studentList {
          margin-bottom: 30px;
          display:none;
          &.showList{
            display:block;
          }
        }
      }
    }
  }
  .echarts-card {
    margin-bottom: 30px;
    .clearfix {
      display: flex;
      .echarts-title {
        font-weight: 700;
        margin-right: 20px;
      }
      .echarts-nav {
        span {
          padding: 4px 10px;
          user-select: none;
          cursor: pointer;
          font-size: 14px;
          &.active {
            color: @title-color;
            border-bottom: 1px solid @title-color;
          }
        }
      }
    }
  }
  .msg-card {
    margin-bottom: 50px;
    .clearfix {
      .fl {
        font-weight: 700;
      }
      .more {
        font-size: 14px;
      }
      .more:hover {
        color: @btn-color;
        cursor: pointer;
        user-select: none;
      }
    }
    .el-row {
      margin: 10px 0;
      color: #888;
      font-size: 14px;
      .grid-content {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
    }
  }
}
</style>