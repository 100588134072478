<template>
  <div class="root">
    <div v-show="showCharts"><div class="main" ref="ld"></div></div>
    <div v-show="!showCharts"><div class="main" ref="zz"></div></div>
  </div>
</template>

<script>
import * as echarts from "echarts/core";
import {
  TitleComponent,
  TooltipComponent,
  LegendComponent,
  GridComponent,
} from "echarts/components";
import { RadarChart, BarChart } from "echarts/charts";
import { CanvasRenderer } from "echarts/renderers";

echarts.use([
  TitleComponent,
  TooltipComponent,
  LegendComponent,
  RadarChart,
  GridComponent,
  BarChart,
  CanvasRenderer,
]);
export default {
  props: ["labelCount", "showCharts"],
  data() {
    return {
      barLabelNameArr: this.labelCount.labelNameArr, // 柱状图数据
      countArr: this.labelCount.countArr, // 雷达图和柱状图都可用
      labelNameArr: [], // 雷达图数据
      max: 5,
    };
  },
  methods: {
    // 数据处理
    filData() {
      // 找出countArr的最大值
      this.countArr.forEach((item) => {
        if (item > 5 && this.max < item) this.max = item;
      });
      // 处理labelNameArr标签
      this.labelNameArr = this.barLabelNameArr.map((item) => {
        return (item = {
          name: item,
          max: this.max,
        });
      });
    },
    // 创建雷达图
    createdEcharts() {
      var chartDom = this.$refs.ld;
      var myChart = echarts.init(chartDom);
      var option;
      option = {
        color: ["#70D0FB"],
        tooltip: {},
        radar: {
          name: {
            textStyle: {
              color: "#aaa",
              backgroundColor: "#eee",
              borderRadius: 3,
              padding: [10, 20],
            },
          },
          indicator: this.labelNameArr,
        },
        series: [
          {
            type: "radar",
            areaStyle: {},
            data: [
              {
                value: this.countArr,
              },
            ],
          },
        ],
      };
      option && myChart.setOption(option);
    },
    // 创建柱状图
    createdBarCharts() {
      var chartDom = this.$refs.zz;
      var myChart = echarts.init(chartDom);
      var option;
      option = {
        xAxis: {
          type: "category",
          data: this.barLabelNameArr,
        },
        yAxis: [
          {
            type: "value",
            name: "y",
            max: this.max,
          },
        ],
        series: [
          {
            data: this.countArr,
            type: "bar",
          },
        ],
      };
      option && myChart.setOption(option);
    },
  },
  created() {
    this.filData();
  },
  mounted() {
    this.createdEcharts()
    this.createdBarCharts()
  },
};
</script>

<style lang="less" scoped>
.root {
  width: 100%;
  height: 400px;
  overflow: hidden;
  .main {
    width: 600px;
    height: 400px;
    margin:0 auto;
  }
}
</style>